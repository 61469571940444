import * as React from 'react'
import { Link, graphql } from 'gatsby'
import Layout from '../../components/layout'
import { cardl, cardGrid, hpmainl } from '../../components/layout.module.css'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'

const ProductDetPage = ({ data }) => {
  return (

    <Layout pageTitle="Ornaments">
      <div className={hpmainl}>
        <div className={cardGrid}>
          {
            data.allMdx.nodes.map(node => (
              <Link to={`${node.slug}`}>
                <div className={cardl}>
                  <article key={node.id}>
                    <GatsbyImage
                      const image={getImage(node.frontmatter.hero_image)}
                      alt={node.frontmatter.hero_image_alt}
                    />
                    <h4>
                      {node.frontmatter.title}
                    </h4>
                    <p>Price: {node.frontmatter.price}</p>
                    <p>Status: {node.frontmatter.availability}</p>
                  </article>
                </div>
              </Link>
            ))
          }
        </div>
      </div>
    </Layout>
  )
}

export const query = graphql`
  query {
    allMdx(sort: {fields: frontmatter___date, order: DESC},
      filter: {
        frontmatter: { category: { eq: "Ornaments" } }
      }      
      ) {
      nodes {
        frontmatter {
          date(formatString: "MMMM D, YYYY")
          title
          hero_image {
            childImageSharp {
              gatsbyImageData
            }
          }
          price
          category
          availability
        }
        id
        slug
      }
    }
  }
`
export default ProductDetPage